import React from "react";
import { useTranslation } from "react-i18next";
import {Alert} from "react-bootstrap";

const ErrorAlert = ({i18nKey, errors}) => {
    const { t } = useTranslation();
    const keys = Object.keys(errors)

    return (
        <Alert variant="danger">
            <div className="alert-message">
                {
                    errors && keys.length === 1 && keys.map((key) => <span className="block sm:inline" key={key}>{t(`${i18nKey}.errors.${key}.${errors[key]}`)}</span>)
                }
                {
                    errors && keys.length > 1 && (
                        <ul className="mb-0">
                            { keys.map((key) => <li key={key}>{t(`${i18nKey}.errors.${key}.${errors[key]}`)}</li>)}
                        </ul>
                    )
                }
            </div>
        </Alert>
    )

    return (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-9" role="alert">
            {
                errors && keys.length === 1 && keys.map((key) => <span className="block sm:inline" key={key}>{t(`${i18nKey}.errors.${key}.${errors[key]}`)}</span>)
            }
            {
                errors && keys.length > 1 && (
                    <ul>
                        { keys.map((key) => <li key={key}>{t(`${i18nKey}.errors.${key}.${errors[key]}`)}</li>)}
                    </ul>
                )
            }
        </div>
    )
}

export default ErrorAlert