import {useContext, createContext} from "react";
import {useUser} from "../hooks/api/user";

const UsersContext = createContext();

const UsersProvider = ({ children }) => {
    const [{users, apiErrors, loading}, {fetchUsers, updateUser, addUser, deleteUser, resetApiErrors, load}] = useUser()

    return <UsersContext.Provider value={{ users, loading, apiErrors, fetchUsers, updateUser, addUser, deleteUser, resetApiErrors, load }}>
        {children}
    </UsersContext.Provider>;
}

export default UsersProvider

export const useUsers = () => {
    return useContext(UsersContext);
};