import React from "react";
import ErrorAlert from "./ErrorAlert";

const ErrorsFormAlert = ({i18nKey, errors}) => {
    let alerts = {}
    Object.keys(errors).map((key) => {
        alerts[key] = errors[key].message
    })

    return (
        <ErrorAlert i18nKey={i18nKey} errors={alerts} />
    )
}

export default ErrorsFormAlert