import React from "react";
import { useTranslation } from "react-i18next";
import {Alert} from "react-bootstrap";

const SuccessAlert = ({message}) => {
    const { t } = useTranslation();

    return (
        <Alert variant="success">
            <div className="alert-message">
                <span className="block sm:inline">{message}</span>
            </div>
        </Alert>
)
}

export default SuccessAlert