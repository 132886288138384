import Cookies from 'js-cookie'

const AUTH_TOKEN_KEY = process.env.REACT_APP_AUTH_TOKEN_KEY

const useToken = () => {
    return {
        token: Cookies.get(AUTH_TOKEN_KEY) ? Cookies.get(AUTH_TOKEN_KEY) : null,
        setToken: (token, expiresIn) => Cookies.set(AUTH_TOKEN_KEY, token, {
            domain: process.env.COOKIE_DOMAIN,
            expires: expiresIn || 49 * 24 * 60 * 60,
        }),
        removeToken: () => Cookies.remove(AUTH_TOKEN_KEY, { path: '/', domain: process.env.COOKIE_DOMAIN }),
    }
}

export default useToken;
