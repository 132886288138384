import React, {useState} from 'react'
import { useForm } from "react-hook-form"
import DisconnectedLayout from "../components/Layout/DisconnectedLayout"
import ErrorAlert from "../components/Layout/ErrorAlert";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import SuccessAlert from "../components/Layout/SuccessAlert";

import Logo from "../images/logo/ATP_logo_HZ_Colors_small.png";
import {Form, Button, Spinner} from 'react-bootstrap';
import {client} from "../api/client";

const PasswordLost = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [clientErrors, setClientErrors] = useState(null);
    const { t } = useTranslation();

    const onSubmit = ({email}) => {
        setIsLoading(true)
        setData(null)
        setClientErrors(null)
        client('user/lost-password', {body: {email}})
            .then(result => {
                if (false === result.success) {
                    setClientErrors(result.errors)
                } else {
                    setData(result)
                }
                setIsLoading(false)
            })
            .catch(result => {
                setClientErrors(result.errors)
                setIsLoading(false)
            })
    }

    return (
        <DisconnectedLayout>
            <div className="text-center mt-4">
                <h1 className="h2">{t('lost-password.title.find-password')}</h1>
                <p className="lead">
                    {t('lost-password.title.sub-title')}
                </p>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="m-sm-3">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            {errors.email &&
                                <ErrorAlert i18nKey='lost-password' errors={{'email': errors.email.message}}/>}
                            {clientErrors && <ErrorAlert i18nKey='lost-password' errors={clientErrors}/>}

                            {data && data.success === true && (
                                <>
                                    <SuccessAlert message={t('lost-password.form.success-message')}/>
                                    <div className="mt-4 text-center">
                                        <p>
                                            <Link to="/" className="text-primary">
                                                {t('lost-password.help.back-to-home')}
                                            </Link>
                                        </p>
                                    </div>
                                </>
                            )}

                            {!data && (
                                <>
                                    <Form.Group className="mb-3" controlId="login.email">
                                        <Form.Label>{t('lost-password.form.email.label')}</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder={t('lost-password.form.email.place-holder')}
                                            size="lg"
                                            {...register("email", {
                                                required: 'error-empty',
                                                pattern: {
                                                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                    message: 'error-not-email',
                                                }
                                            })}
                                            disabled={isLoading}
                                            isInvalid={errors && errors.email}
                                        />
                                    </Form.Group>

                                    <div className="d-grid gap-2 mt-3">
                                        <Button
                                            disabled={isLoading}
                                            type="submit"
                                            size="lg"
                                        >
                                            {isLoading && <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                style={{marginRight: '10px'}}
                                            />}
                                            {t('lost-password.form.submit')}
                                        </Button>
                                    </div>
                                </>)}
                        </Form>
                    </div>
                </div>
            </div>
            <div className="text-center mb-3">
                {t('lost-password.help.password-finded')}
                <Link to="/login" className="text-primary">
                    {t('lost-password.help.i-connect')}
                </Link>
            </div>
        </DisconnectedLayout>
    )
}

export default PasswordLost