import { useEffect, useRef, useState } from 'react';
import {Link, NavLink} from 'react-router-dom';
import {useAuth} from "../../context/AuthContext";
import {useTranslation} from "react-i18next";

const DropdownUser = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const {user, logOut} = useAuth()
  const { t } = useTranslation();

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
      <li className="nav-item dropdown">
        <Link
            className={`nav-link dropdown-toggle d-none d-sm-inline-block${dropdownOpen ? ' show' : ''}`}
            href="#"
            data-bs-toggle="dropdown"
            onClick={() => setDropdownOpen(!dropdownOpen)}
            aria-expanded={dropdownOpen}
            ref={trigger}
            to="#"
        >
          <span className="text-dark">{user.firstName} {user.lastName}</span>
        </Link>
        <div
            ref={dropdown}
            className={`dropdown-menu dropdown-menu-end${dropdownOpen ? ' show' : ''}`}
            data-bs-popper="static"
        >
          <Link
              to="/profile"
              className="dropdown-item"
          >
            <i className="align-middle me-1"></i>
            {t('layout.menu.my-profile')}
          </Link>
          <div className="dropdown-divider"></div>
          <button
              onClick={() => logOut()}
              className="dropdown-item"
          >
            {t('layout.menu.logout')}
          </button>
        </div>
      </li>
  )
};

export default DropdownUser;
