import {useCallback, useReducer} from "react";
import {client} from "../../api/client";
import useToken from "../useToken";

function reducer(state, action) {
    switch (action.type) {
        case 'FETCH_USERS':
            return { ...state, loading: true }
        case 'RESET_ERRORS':
            return { ...state, loading: false, apiErrors: null }
        case 'SET_USERS':
            return { ...state, users: action.payload, loading: false, apiErrors: null }
        case 'ADD_USER':
            return { ...state, users: [...state.users, ...action.payload], apiErrors: null }
        case 'ADD_ERRORS':
            return { ...state, apiErrors: action.payload, loading: false }
        case 'UPDATE_USER':
            return { ...state, apiErrors: null, loading: false, users: state.users.map(user => {
                if (user.id === parseInt(action.payload.id)) {
                    return action.payload
                }

                return user
            })}
        case 'DELETE_USER':
            return { ...state, apiErrors: null, loading: false, users: state.users.filter(user => user.id !== parseInt(action.payload))}
        default:
            throw new Error('Action inconnue ' + action.type)
    }
}
export function useUser() {
    const {token} = useToken()
    const [state, dispatch] = useReducer(reducer, {
        loading: true,
        users: null,
        apiErrors: null
    })

    const fetchUsers = useCallback(async () => {
        client('users', {token})
            .then(result => {
                dispatch({type: 'SET_USERS', payload: result.users})
            })
    })

    const updateUser = useCallback(async (id, {email, firstName, lastName, role}) => {
        dispatch({ type: 'FETCH_USERS' })
        client(`user/${id}`, {body: {email, firstName, lastName, role}, method: 'PUT', token})
            .then(result => {
                dispatch({ type: 'UPDATE_USER', payload: result.user })
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const addUser = useCallback(async ({email, firstName, lastName, role}) => {
        dispatch({ type: 'FETCH_USERS' })
        //fetch add user dans l'api
        client(`user`, {body: {email, firstName, lastName, role}, token})
            .then(result => {
                fetchUsers()
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
        dispatch({ type: 'ADD_USER', payload: [{email, firstName, lastName, role}] })
    })

    const deleteUser = useCallback(async (id) => {
        dispatch({ type: 'FETCH_USERS' })
        client(`user/${id}`, {method: 'DELETE', token})
            .then(result => {
                dispatch({ type: 'DELETE_USER', payload: id })
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const resetApiErrors = () => {
        dispatch({ type: 'RESET_ERRORS' })
    }

    const load = useCallback(async() => {
        dispatch({ type: 'FETCH_USERS' })
    })

    return [state, { fetchUsers, updateUser, addUser, deleteUser, resetApiErrors, load }]
}