import React from 'react';

const DisconnectedLayout = ({ children }) => {
    return (
        <div className="main d-flex justify-content-center w-100">
            <main className="content d-flex p-0">
                <div className="container d-flex flex-column">
                    <div className="row h-100">
                        <div className="col-sm-12 col-md-10 col-lg-7 col-xl-6 mx-auto d-table h-100">
                            <div className="d-table-cell align-middle">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default DisconnectedLayout;