import React, {useState} from 'react'
import { useForm } from "react-hook-form"
import DisconnectedLayout from "../components/Layout/DisconnectedLayout"
import ErrorAlert from "../components/Layout/ErrorAlert";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import SuccessAlert from "../components/Layout/SuccessAlert";

import {Form, Button, Spinner} from 'react-bootstrap';
import {client} from "../api/client";

const ValidateInvitationUser = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [clientErrors, setClientErrors] = useState(null);
    const { t } = useTranslation();

    let { token } = useParams();

    const onSubmit = ({password, password2}) => {
        setIsLoading(true)
        setData(null)
        setClientErrors(null)
        client('user/validate-invitation', {body: {invitationToken: token, password, password2}})
            .then(result => {
                if (false === result.success) {
                    setClientErrors(result.errors)
                } else {
                    setData(result)
                }
                setIsLoading(false)
            })
            .catch(result => {
                setClientErrors(result.errors)
                setIsLoading(false)
            })
    }

    return (
        <DisconnectedLayout>
            <div className="text-center mt-4">
                <h1 className="h2">{t('validate-invitation.title')}</h1>
                <p className="lead">
                    {t('validate-invitation.title.sub-title')}
                </p>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="m-sm-3">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            {errors && Object.keys(errors).length > 1 &&
                                <ErrorAlert i18nKey='validate-invitation' errors={errors} />}
                            {clientErrors && <ErrorAlert i18nKey='validate-invitation' errors={clientErrors}/>}

                            {data && data.success === true && (
                                <>
                                    <SuccessAlert message={t('validate-invitation.form.success-message')}/>
                                    <div className="mt-4 text-center">
                                        <p>
                                            <Link to="/" className="text-primary">
                                                {t('validate-invitation.help.back-to-login')}
                                            </Link>
                                        </p>
                                    </div>
                                </>
                            )}

                            {!data && (
                                <>
                                    <Form.Group className="mb-3" controlId="login.email">
                                        <Form.Label>{t('validate-invitation.form.password.label')}</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder={t('validate-invitation.form.password.place-holder')}
                                            size="lg"
                                            {...register("password", {
                                                required: 'error-empty',
                                            })}
                                            disabled={isLoading}
                                            isInvalid={errors && errors.password}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="login.email">
                                        <Form.Label>{t('validate-invitation.form.password2.label')}</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder={t('validate-invitation.form.password2.place-holder')}
                                            size="lg"
                                            {...register("password2", {
                                                required: 'error-empty',
                                            })}
                                            disabled={isLoading}
                                            isInvalid={errors && errors.password2}
                                        />
                                    </Form.Group>
                                    <div className="d-grid gap-2 mt-3">
                                        <Button
                                            disabled={isLoading}
                                            type="submit"
                                            size="lg"
                                        >
                                            {isLoading && <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                style={{marginRight: '10px'}}
                                            />}
                                            {t('validate-invitation.form.submit')}
                                        </Button>
                                    </div>
                                </>)}
                        </Form>
                    </div>
                </div>
            </div>
            <div className="text-center mb-3">
                {t('lost-password.help.password-finded')}
                <Link to="/login" className="text-primary">
                    {t('lost-password.help.i-connect')}
                </Link>
            </div>
        </DisconnectedLayout>
    )
}

export default ValidateInvitationUser