import React, { useState, ReactNode } from 'react';
import Header from '../Header/index';
import Sidebar from '../Sidebar/index';

const DefaultLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  return (
      <div className="wrapper">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>

        <div className="main">
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>

          <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
              <main className="content">
                  <div className="container-fluid p-0">
                      {children}
                  </div>
              </main>
          </div>
        </div>
      </div>
  );
};

export default DefaultLayout;
