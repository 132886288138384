import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {useTranslation} from "react-i18next";

const PageTitle = ({ title }) => {
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = title;
  }, [location, t(title)]);

  return null; // This component doesn't render anything
};

export default PageTitle;
