import React from 'react';
import DefaultLayout from './components/Layout/DefaultLayout';
import {Route, Routes, useLocation} from "react-router-dom";
import Login from "./pages/Login";
import PageTitle from "./components/PageTitle";
import PasswordLost from "./pages/PasswordLost";
import ProtectedRoute from "./components/ProtectedRoute";
import AuthProvider from "./context/AuthContext";
import Users from "./pages/Users";
import {useTranslation} from "react-i18next";
import UpdateUser from "./pages/UpdateUser";
import UsersProvider from "./context/UsersContext";
import AddUser from "./pages/AddUser";
import DeleteUser from "./pages/DeleteUser";
import ValidateInvitationUser from "./pages/ValidateInvitationUser";

function App() {
    const { t } = useTranslation();

  return (
      <AuthProvider>
          <Routes>
              <Route
                  path="/"
                  index
                  element={
                      <ProtectedRoute>
                          <DefaultLayout>
                              <h1 className="text-3xl font-bold underline">
                              </h1>
                          </DefaultLayout>
                      </ProtectedRoute>
                  }
              />
              <Route
                  path="login"
                  element={
                      <>
                          <PageTitle title="Se connecter" />
                          <Login />
                      </>
                  }

              />
              <Route
                  path="password-lost"
                  element={
                      <>
                          <PageTitle title="Retrouver son mot de passe" />
                          <PasswordLost />
                      </>
                  }
              />
              <Route
                  path="validate-invitation/:token"
                  element={
                      <>
                          <PageTitle title="Retrouver son mot de passe" />
                          <ValidateInvitationUser />
                      </>
                  }
              />
              <Route
                  path="profile"
                  element={
                      <ProtectedRoute>
                          <PageTitle title={t('users.list.title')} />
                          <DefaultLayout></DefaultLayout>
                      </ProtectedRoute>
                  }
              />
              <Route
                  path="users"
                  element={
                      <ProtectedRoute >
                          <UsersProvider>
                              <Users />
                          </UsersProvider>
                      </ProtectedRoute>
                  }
              >
                  <Route
                      path="new"
                      element={
                          <>
                              <PageTitle title={t('users.list.title')} />
                              <AddUser />
                          </>
                      }
                  />
                  <Route
                      path="update/:id"
                      element={
                        <>
                              <PageTitle title={t('users.list.title')} />
                              <UpdateUser />
                        </>
                      }
                  />
                  <Route
                      path="delete/:id"
                      element={
                          <>
                              <PageTitle title={t('users.list.title')} />
                              <DeleteUser />
                          </>
                      }
                  />
              </Route>
          </Routes>
      </AuthProvider>
  )
}

export default App;
