import React, {useEffect, useState} from 'react';
import {Modal, Button, Form, Row} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import ErrorAlert from "../components/Layout/ErrorAlert";
import {useUsers} from "../context/UsersContext";
import {useNavigate, useOutletContext} from "react-router-dom";

const AddUser = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const [canClose, setCanClose] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const {loading, apiErrors, addUser, load, resetApiErrors} = useUsers()
    const {setSuccessMessage} = useOutletContext()

    useEffect(() => {
        setSuccessMessage(null)
    }, []);

    useEffect(() => {
        if (canClose && !loading && !apiErrors) {
            setSuccessMessage(t('users.add_user.success_message'))
            handleClose()
        }

    }, [canClose, loading, apiErrors]);

    const onSubmit = async (inputs) => {
        load()
        await addUser(inputs)
        setCanClose(true)
    }

    const handleClose = () => {
        resetApiErrors()
        navigate('/users')
    }

    return (
        <Modal
            show={true}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('users.add_user.title')}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    {apiErrors && <div className="mb-3"><ErrorAlert i18nKey='users.add_user' errors={apiErrors}/></div>}
                    <Row>
                        <Form.Group className="mb-3 col-md-6" controlId="user.firstName">
                            <Form.Label>{t('users.form.firstName.label')}</Form.Label>
                            <Form.Control
                                type="firstName"
                                placeholder={t('users.form.firstName.place-holder')}
                                size="lg"
                                {...register("firstName", {
                                    required: 'error-empty',
                                })}
                                disabled={isLoading}
                                isInvalid={errors && errors.firstName}
                            />
                            {errors && errors.firstName && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`users.form.firstName.errors.${errors.firstName.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3 col-md-6" controlId="user.lastName">
                            <Form.Label>{t('users.form.lastName.label')}</Form.Label>
                            <Form.Control
                                type="lastName"
                                placeholder={t('users.form.lastName.place-holder')}
                                size="lg"
                                {...register("lastName", {
                                    required: 'error-empty',
                                })}
                                disabled={isLoading}
                                isInvalid={errors && errors.lastName}
                            />
                            {errors && errors.lastName && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`users.form.lastName.errors.${errors.lastName.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3" controlId="login.email">
                        <Form.Label>{t('users.form.email.label')}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t('users.form.email.place-holder')}
                            size="lg"
                            {...register("email", {
                                required: 'error-empty',
                                pattern: {
                                    value: /^[a-zA-Z0-9./+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                    message: 'error-not-email',
                                }
                            })}
                            disabled={isLoading}
                            isInvalid={errors && errors.email}
                        />
                        {errors && errors.email && (
                            <Form.Control.Feedback type="invalid">
                                {t(`users.form.email.errors.${errors.email.message}`)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="login.role">
                        <Form.Label>{t('users.form.role.label')}</Form.Label>
                        <Form.Select
                            type="role"
                            size="lg"
                            {...register("role", {
                                required: 'error-empty',
                            })}
                            disabled={isLoading}
                            isInvalid={errors && errors.role}
                        >
                            <option value="">{t('users.form.role.place-holder')}</option>
                            <option value="admin">{t('users.role.admin')}</option>
                            <option value="manager">{t('users.role.manager')}</option>
                            <option value="controller">{t('users.role.controller')}</option>
                        </Form.Select>
                        {errors && errors.role && (
                            <Form.Control.Feedback type="invalid">
                                {t(`users.form.role.errors.${errors.role.message}`)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('users.add_user.button.cancel')}
                    </Button>
                    <Button variant="primary" type="submit">
                        {t('users.add_user.button.save')}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AddUser