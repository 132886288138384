import {useContext, createContext, useState, useEffect} from "react";
import useToken from "../hooks/useToken";
import { useNavigate } from "react-router-dom";
import {client} from "../api/client";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const {token, setToken, removeToken} = useToken()
    const [user, setUser] = useState(null);
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (token && !user) {
            client('user/me', {token})
                .then(result => {
                    setUser(result.user)
                    setIsLoading(false)
                })
                .catch(result => {
                    setErrors(result.errors)
                    setUser(null);
                    removeToken();
                    setIsLoading(false)
                    navigate('/login')
                })
        }
        if (!user && !token) {
            const pathname = window.location.pathname;
            const publicPathnames = ["login", "lost-password", "validate-invitation"]
            setUser(null);
            removeToken();
            setIsLoading(false)
            if (undefined !== publicPathnames.find(publicPathname => pathname.includes('publicPathname'))) {
                navigate('/login')
            }
        }
    }, []);

    const loginAction = async ({email, password}) => {
        setIsLoading(true)
        client('login_check', {body: {username: email, password}})
            .then(result => {
                setToken(result.token)
                client('user/me', {token: result.token})
                    .then(result => {
                        setUser(result.user)
                        setIsLoading(false)
                        navigate('/')
                    })
                    .catch(result => {
                        setErrors(result.errors)
                        setUser(null);
                        removeToken();
                        setIsLoading(false)
                    })
            })
            .catch(result => {
                setErrors(result.errors)
                setUser(null);
                removeToken();
                setIsLoading(false)
            })
    }

    const logOut = () => {
        setUser(null);
        removeToken();
        navigate("/login");
    }

    return <AuthContext.Provider value={{ token, user, loginAction, logOut, errors, isLoading }}>
        {children}
    </AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};