import useToken from "../hooks/useToken";

const apiURL = process.env.REACT_APP_API_URL

const client = async (endpoint, {method, body, formData, token, params}) => {
    const config = {
        method: method ? method : (body ? 'POST' : 'GET'),
        body: formData ? formData : (body ? JSON.stringify(body) : undefined),
        headers: {
            Authorization: token ? `Bearer ${token}` : undefined,
            //'Content-Type': 'application/json;charset=utf-8',
        },
    }

    const url = new URL(`${apiURL}/api/${endpoint}`)
    if (params) {
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    }

    return window.fetch(url, config).then(async response => {
        if ((response.status === 401 || response.status === 403) && endpoint !== 'login_check') {
            const {removeToken} = useToken()

            removeToken()
            window.location.assign(window.location)

            return Promise.reject({message: 'Merci de vous identifier.'})
        }
        const data = await response.json()
        if (response.ok) {
            return data
        } else {
            return Promise.reject(data)
        }
    })

}

export {client}