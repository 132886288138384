import React from 'react';
import { useAuth } from "../context/AuthContext";
import { useForm } from "react-hook-form"
import {useTranslation} from "react-i18next";

import DisconnectedLayout from "../components/Layout/DisconnectedLayout";
import ErrorsFormAlert from "../components/Layout/ErrorsFormAlert";
import ErrorAlert from "../components/Layout/ErrorAlert";

import {Form, Button, Spinner} from 'react-bootstrap';
import {Link} from "react-router-dom";

const Login = () => {
    const {loginAction, errors: authErrors, isLoading} = useAuth();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()
    const { t } = useTranslation();

    const onSubmit = (inputs) => {
        loginAction(inputs)
    }

    return (
        <DisconnectedLayout>
            <div className="text-center mt-4">
                <h1 className="h2">{t('login.title.connect')}</h1>
                <p className="lead">
                    {t('login.title.sub_title')}
                </p>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="m-sm-3">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            {errors && Object.keys(errors).length > 0 &&
                                <ErrorsFormAlert i18nKey='login' errors={errors}/>}
                            {authErrors && <ErrorAlert i18nKey='login' errors={authErrors}/>}

                            <Form.Group className="mb-3" controlId="login.email">
                                <Form.Label>{t('login.form.email.label')}</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder={t('login.form.email.place-holder')}
                                    size="lg"
                                    {...register("email", {
                                        required: 'error-empty',
                                        pattern: {
                                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                            message: 'error-not-email',
                                        }
                                    })}
                                    disabled={isLoading}
                                    isInvalid={errors && errors.email}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="login.password">
                                <Form.Label>{t('login.form.password.label')}</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder={t('login.form.password.place-holder')}
                                    size="lg"
                                    {...register("password", {
                                        required: 'error-empty',
                                    })}
                                    disabled={isLoading}
                                    isInvalid={errors && Object.keys(errors).length > 0}
                                />
                                <small>
                                    <Link to="/password-lost" className="text-primary">
                                        {t('login.help.go-to-password-lost')}
                                    </Link>
                                </small>
                            </Form.Group>

                            <div className="d-grid gap-2 mt-3">
                                <Button
                                    disabled={isLoading}
                                    type="submit"
                                    size="lg"
                                >
                                    {isLoading && <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        style={{marginRight: '10px'}}
                                    />}
                                    {t('login.form.submit')}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </DisconnectedLayout>
    )
}

export default Login