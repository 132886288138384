import React, {useContext} from "react";
import {Navigate, Outlet} from "react-router-dom";
import {useAuth} from "../context/AuthContext";

const ProtectedRoute = ({children}) => {
  const { user, isLoading } = useAuth()

  if (isLoading) {
    return <div className="flex h-screen items-center justify-center dark:bg-boxdark-2 dark:text-bodydark">
      <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid  border-t-transparent"></div>
    </div>
  }

  if (!user) {
    return <Navigate to="/login"/>;
  }

  return children;
};

export default ProtectedRoute;